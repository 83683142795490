import React, { useState, useEffect } from 'react';
import { useUser } from "@clerk/clerk-react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import '../App.css';
function UserProfile() {
  const { user } = useUser();
  const [userData, setUserData] = useState({
    fullname: user ? user.fullName : 'Default Name', // Set initial value to Clerk full name
    jtgrantkey: '',
    jtorgid: '',
    graphTwoSettingOne: 'Lead Source',
    graphFiveSettingOne: 'Job Type',
    calendarTaskType: 'Pay Phase', // Add default value for calendar setting
    subTier: ''
  });
  const [updateSuccess, setUpdateSuccess] = useState(false); // State for update success message

  const fetchUserData = async (userId) => {
    const userDocRef = doc(db, "users", userId); // Use Clerk unique user ID
    const userDocSnap = await getDoc(userDocRef);
    
    if (userDocSnap.exists()) {
      const data = userDocSnap.data();
      setUserData(data);
    } else {
      console.log("No such document!");
    }
  };

  useEffect(() => {
    if (user) {
      // Fetch other user data from Firestore
      fetchUserData(user.id);
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user) {
      try {
        const userDocRef = doc(db, "users", user.id);
        const updateData = {};
        
        if (userData.jtgrantkey !== undefined) updateData.jtgrantkey = userData.jtgrantkey;
        if (userData.jtorgid !== undefined) updateData.jtorgid = userData.jtorgid;
        if (userData.graphTwoSettingOne !== undefined) updateData.graphTwoSettingOne = userData.graphTwoSettingOne;
        if (userData.graphFiveSettingOne !== undefined) updateData.graphFiveSettingOne = userData.graphFiveSettingOne;
        if (userData.calendarTaskType !== undefined) updateData.calendarTaskType = userData.calendarTaskType;

        await setDoc(userDocRef, updateData, { merge: true });
        setUpdateSuccess(true);
        console.log("User settings updated successfully");
      } catch (error) {
        console.error("Error updating user settings:", error);
        // Optionally set an error state here to display to the user
      }
    }
  };

  if (!user) return <div>Loading...</div>;

 // Check if user and publicMetadata exist
 if (user && user.publicMetadata) {
  const subTier = user.publicMetadata.subTier; // Access the subscription tier
  console.log("subTier", subTier, "user ", user.fullName);
} else {
  console.log("User or publicMetadata not available");
}

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h1>User Settings</h1>
        <p>Subscription Tier: {user.publicMetadata?.subTier}</p>
        <h2>Connect Your JobTread Account</h2>
        <p>
          Name: <input type="text" name="fullname" value={user.fullName} readOnly />
          
        </p>
        <p>
          JobTread Grant Key: <input type="text" name="jtgrantkey" value={userData.jtgrantkey} onChange={handleChange} />
        </p>
        <p>
          JobTread Organization ID: <input type="text" name="jtorgid" value={userData.jtorgid} onChange={handleChange} />
        </p>
        <hr />
        <h2>Estimates Graph Settings</h2>
        <p><u>Graph One Settings</u></p>
        <p>No additional settings available for Graph One at this time.</p>
        <p><u>Pie Graph Settings</u></p>
        <div className="form-group">
          <label>Custom Field Name: 
            <br />Use the name of the custom field you want to track. It's suggested to track the Job Type here, but other custom fields will work too.</label>
          <input 
            type="text" 
            name="graphFiveSettingOne" 
            value={userData.graphFiveSettingOne || 'Job Type'} 
            onChange={handleChange} 
          />
        </div>
        <p><u>Bar Graph Settings</u></p>
        <div className="form-group">
          <label>Custom Field Name: 
            <br />Use the name of the custom field you want to track. It's suggested to track the customer Lead Source here, but other custom fields will work too.</label>
          <input 
            type="text" 
            name="graphTwoSettingOne" 
            value={userData.graphTwoSettingOne || 'Lead Source'} 
            onChange={handleChange} 
          />
        </div>
        <hr />
        <h2>Calendar Settings</h2>
        <div className="form-group">
          <label>Task Type to Display: 
            <br />Enter the name of the task type you want to display on the calendar. For example, '013 Pay Phase'.</label>
          <input 
            type="text" 
            name="calendarTaskType" 
            value={userData.calendarTaskType || 'Pay Phase'} 
            onChange={handleChange} 
          />
        </div>
        <hr />
        {updateSuccess && <p>Settings updated successfully!</p>}
        <button type="submit" className='button-secondary button'>Update All Settings</button>
        
      </form>
    </div>
  );
}

export default UserProfile;
