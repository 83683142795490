import React from 'react';
import { Link } from 'react-router-dom';
import styles from './SidebarNav.module.css'; 

const SidebarNav = () => {
  return (
    <div className={styles.sidebarNav}>
      <ul>
        <li><Link to="/" activeClassName="active"><i class="fa-solid fa-bolt-lightning fa-1x" ></i> About</Link></li>
        <li><Link to="/library" activeClassName="active"><i class="fa-solid fa-book fa-1x" ></i> Library</Link></li>
        <hr />
        <li><Link to="/dashboard" activeClassName="active"><i class="fa-solid fa-chart-line fa-1x" ></i> Dashboard</Link></li>
        <li><Link to="/calendar" activeClassName="active"><i class="fa-regular fa-calendar fa-1x" ></i> Cash Flow</Link></li>        
        <li><Link to="/inventory" activeClassName="active"><i class="fa-solid fa-clipboard fa-1x" ></i> Inventory</Link></li>
        <li><Link to="/print" activeClassName="active"><i class="fa-solid fa-pen-to-square fa-1x" ></i> Print Reports</Link></li>
        <hr />
        <li><Link to="/settings" activeClassName="active"><i class="fa-solid fa-gear fa-1x" ></i> Settings</Link></li>
        
    </ul>
    </div>
  );
};

export default SidebarNav;
