import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { db } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';
import '../App.css';

const StarRating = ({ rating }) => (
  <span className="library-item-rating">
    {'★'.repeat(Math.floor(rating))}
    {rating % 1 >= 0.5 ? '½' : ''}
    {'☆'.repeat(5 - Math.ceil(rating))}
    ({rating.toFixed(1)})
  </span>
);

const DownloadIcon = () => (
  <span style={{ fontSize: '1.2em', marginRight: '5px' }}><i class="fa-solid fa-heart fa-1x" ></i></span>
);

const LibraryApi = () => {
  const [libraryItems, setLibraryItems] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLibrary = async () => {
      try {
        console.log("Attempting to fetch from 'apidocs' collection...");
        const libraryCollection = collection(db, 'apidocs');
        const librarySnapshot = await getDocs(libraryCollection);
        const items = librarySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        // Sort items by likes in descending order
        const sortedItems = items.sort((a, b) => b.likes - a.likes);
        setLibraryItems(sortedItems);
      } catch (error) {
        console.error("Error fetching library:", error);
        setError(error.message);
      }
    };

    fetchLibrary();
  }, []);

  const handleUpload = () => {
    navigate('/apiupload');
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="library-container">
      <div className="library-header">
        <h1>Script & API Library</h1>
        <button className="button button-white" onClick={handleUpload}>+ Write New Doc</button>
      </div>
      <div className="library-items">
        {libraryItems.map(item => (
          <div key={item.id} className="library-item">
            <div className="library-item-header">
              <h3 className="library-item-title">{item.name}</h3>
              <div className="library-item-metrics">
                <span className="library-item-likes">
                  <DownloadIcon /> {item.likes}
                </span>
              </div>
            </div>
            <p className="library-item-description">{item.desc.substring(0, 150)}...</p>
            <div className="library-item-actions">
              <a href={`/library/api/${item.id}`}><button className="button button-white">
                Read More
              </button></a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LibraryApi;
