import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { signInAnonymously } from 'firebase/auth';
import { doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { db, auth } from '../firebase'; // Adjust this import based on your Firebase setup
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import MarkdownIt from 'markdown-it';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';  // Your theme
import '../App.css';

const LibraryItem = () => {
  const { id } = useParams();  // Capture the document ID from URL
  const [item, setItem] = useState(null);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);  // Check if in edit mode
  const navigate = useNavigate();

  useEffect(() => {
    const fetchItem = async () => {
      try {
        await signInAnonymously(auth);

        const docRef = doc(db, 'library', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setItem({ id: docSnap.id, ...data });
          setDescription(data.desc);  // Set current description for editing
        } else {
          setError('No such document exists');
        }
      } catch (err) {
        console.error("Error fetching document:", err);
        setError('Error fetching document. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchItem();

    // Check if the path is in edit mode
    setIsEditing(window.location.pathname.startsWith('/upload/'));
  }, [id]);

  const handleEditorChange = ({ text }) => {
    setDescription(text);
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(db, 'library', id);
      await updateDoc(docRef, { desc: description });
      alert('Document updated successfully.');
      navigate(`/library/${id}`);  // Redirect to the view page
    } catch (err) {
      console.error("Error updating document:", err);
      setError('An error occurred while updating the document. Please try again.');
    }
  };

  const handleDownload = async () => {
    try {
      const link = document.createElement('a');
      link.href = item.csvFileURL;
      link.download = item.name || 'download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      const docRef = doc(db, 'library', id);
      await updateDoc(docRef, { downloads: increment(1) });

      setItem(prevItem => ({
        ...prevItem,
        downloads: prevItem.downloads + 1
      }));
    } catch (error) {
      console.error("Error downloading file or updating download count:", error);
    }
  };


  // markdown parser
  const editButton2 = () => {
    if (item.authorID  === auth.currentUser.uid) {
      return <Link to={`/upload/${id}`}>
          <button className="button button-white">Edit</button>
        </Link>
    }
    return null;
  }
  
  const mdParser = new MarkdownIt({
    highlight: (str, lang) => {
      if (lang && hljs.getLanguage(lang)) {
        try {
          return `<pre class="hljs"><code>${hljs.highlight(str, { language: lang }).value}</code></pre>`;
        } catch (__) {}
      }
      return `<pre class="hljs"><code>${hljs.highlightAuto(str).value}</code></pre>`;
    }
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!item) return <div>No item found</div>;

  return (
    <div style={{ padding: '20px' }}>
      <div className="library-header">
        <Link to="/library/templates" style={{ textDecoration: 'none', color: 'blue' }}>← Back to API Library</Link>
        {editButton2()}
      </div>

      <div className="library-header">
        <div>
          <h1>{item.name}</h1>
          <p>Uploaded by: {item.author}</p>
        </div>
        <div>
          <button onClick={handleDownload} className="button button-secondary">Download File</button>
          <br />
          <span style={{ marginLeft: '20px' }}>{item.downloads} downloads</span>
        </div>
      </div>

      {isEditing ? (
        <form onSubmit={handleSaveChanges}>
          <MdEditor
            style={{ height: '400px' }}
            renderHTML={text => mdParser.render(text)}
            onChange={handleEditorChange}
            value={description}
          />
          <button type="submit" className="button button-primary">Save Changes</button>
        </form>
      ) : (
        <p>{item.desc}</p>
      )}

      {item.imageURL && (
        <img src={item.imageURL} alt={item.name} style={{ maxWidth: '100%', marginBottom: '20px' }} />
      )}
    </div>
  );
};

export default LibraryItem;