import React, { useState } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/javascript/javascript';
import { JSONTree } from 'react-json-tree';

const CodeView = () => {
  const [jsonInput, setJsonInput] = useState('{}');
  const [parsedJson, setParsedJson] = useState({});

  // Custom colors for JSONTree
  const customTheme = {
    scheme: 'default',
    base00: '#282c34', // background
    base08: '#f92672', // error key
    base0B: '#a6e22e', // boolean and numbers
    base0A: '#f4bf75', // string & dates
    base0D: '#66d9ef', // key
    base0E: '#ae81ff', // null
  };

  const handleEditorChange = (editor, data, value) => {
    setJsonInput(value);
    try {
      const parsed = JSON.parse(value);
      setParsedJson(parsed);
    } catch (err) {
      console.error('Invalid JSON');
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: '100vh' }}>
      {/* Code Editor */}
      <div style={{ flex: 1, padding: '10px' }}>
        <h3>JSON Input</h3>
        <CodeMirror
          value={jsonInput}
          options={{
            mode: 'application/json',
            theme: 'material',
            lineNumbers: true,
            indentWithTabs: true,
            tabSize: 1,
          }}
          onBeforeChange={handleEditorChange}
        />
      </div>
    </div>
  );
};

export default CodeView;