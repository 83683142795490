import React, { useRef, useEffect, useState } from "react";
import GanttChart from "./GanttChart";
import { fetchQueryGanttOne, fetchQueryGanttTwo } from "./queries";
import { useUser } from "@clerk/clerk-react";
import axios from "axios";
const Gantt = () => {
  const [zoomLevel, setZoomLevel] = useState(1); // Track zoom level
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useUser();

  useEffect(() => {
    const fetchAllTasks = async () => {
      try {
        setLoading(true);
        // First, get all active jobs
        const activeJobsResponse = await fetchQueryGanttOne(user.id);
        const response1 = await axios.post(`${process.env.REACT_APP_API_URL}/tasks`, activeJobsResponse, {
            headers: { 'Content-Type': 'application/json' }
          });
        // Make sure we're accessing the correct path to jobs array
        const activeJobs = response1?.data?.organization?.jobs?.nodes || [];

        // Add a check to ensure activeJobs is an array
        if (!Array.isArray(activeJobs)) {
          console.error('Active jobs is not an array:', activeJobs);
          return;
        }

       // Then fetch tasks for each job
        const allTasksPromises = activeJobs.map(job => 
          fetchQueryGanttTwo(user.id, job.id)
            .then(async taskQuery => {
              const response2 = await axios.post(`${process.env.REACT_APP_API_URL}/tasks`, taskQuery, {
                headers: { 'Content-Type': 'application/json' }
              });
              const jobTasks = response2.data?.job?.tasks?.nodes || [];
              return jobTasks.map(task => ({
                ...task,
                name: response2.data?.job?.name,
                start: task.startDate,
                end: task.endDate,
                taskName: task.name
              }));
            })
        );

        // Wait for all task queries to complete
        const taskResults = await Promise.all(allTasksPromises);
        
        // Flatten and filter out tasks without dates
        const flattenedTasks = taskResults
          .flat()
          .filter(task => task.start && task.end);

        setTasks(flattenedTasks);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllTasks();
  }, [user.id]); // Add user.id as dependency

  // Handlers for zoom in and zoom out
  const handleZoomIn = () => setZoomLevel((prevZoom) => Math.min(prevZoom * 1.2, 10));
  const handleZoomOut = () => setZoomLevel((prevZoom) => Math.max(prevZoom / 1.2, 0.2));

  return (
    <div className="gantt-container">
      <div className="gantt-header">
          <h1>Gantt Chart</h1>
          <div className="zoom-controls pill-right-align">
            <button className="button button-white" onClick={handleZoomIn}>Zoom In</button>
            <button className="button button-white" onClick={handleZoomOut}>Zoom Out</button>
          </div>
      </div>
      <div className="gantt-scroll-container">     
        <GanttChart 
          tasks={tasks}
          zoomLevel={zoomLevel}
        />
      </div>
    </div>
  );
};

export default Gantt;