import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { signInAnonymously } from 'firebase/auth';
import { doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { db, auth } from '../firebase'; // Adjust this import based on your Firebase setup
import hljs from 'highlight.js/lib/common';
import 'highlight.js/styles/rainbow.css';
import MarkdownIt from 'markdown-it';
import 'react-markdown-editor-lite/lib/index.css';
import DOMPurify from 'dompurify';
import '../App.css';

// Define handleCopy globally
window.handleCopy = (e) => {
  if (e.target.classList.contains('copy-btn')) {
    const code = e.target.closest('.code-header').nextElementSibling.innerText;
    navigator.clipboard.writeText(code).then(() => {
      alert('Code copied to clipboard!');
    });
  }
};

const ApiItem = () => {
  const [item, setItem] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [isLiking, setIsLiking] = useState(false);
  const [hasLiked, setHasLiked] = useState(false);

  useEffect(() => {
    const fetchItem = async () => {
      try {
        // Attempt anonymous sign-in
        await signInAnonymously(auth);
        
        const docRef = doc(db, 'apidocs', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setItem({ id: docSnap.id, ...docSnap.data() });
        } else {
          setError('No such document exists');
        }
      } catch (err) {
        console.error("Error fetching document:", err);
        setError('Error fetching document. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchItem();

    // Check if user has already liked this item
    const likedItems = JSON.parse(localStorage.getItem('likedItems') || '[]');
    setHasLiked(likedItems.includes(id));
  }, [id]);

  const handleLike = async () => {
    if (isLiking || hasLiked) return; // Prevent multiple likes

    setIsLiking(true);
    try {      
      // Update the like counter in the database
      const docRef = doc(db, 'apidocs', id);
      await updateDoc(docRef, {
        likes: increment(1)
      });
      
      // Update the local state
      setItem(prevItem => ({
        ...prevItem,
        likes: (prevItem.likes || 0) + 1
      }));

      // Mark this item as liked in local storage
      const likedItems = JSON.parse(localStorage.getItem('likedItems') || '[]');
      likedItems.push(id);
      localStorage.setItem('likedItems', JSON.stringify(likedItems));
      setHasLiked(true);
    } catch (error) {
      console.error("Error updating like count:", error);
    } finally {
      setIsLiking(false);
    }
  };

  // Markdown parser init 
  const mdParser = new MarkdownIt({
    highlight: (str, lang) => {
      try {
        return `
      <div class="code-header">${lang}</div><pre class="hljs">
      <code>${hljs.highlight(str, { language: lang }).value}</code></pre>`;
      } catch (err) {
        return `<div class="code-header">code</div><pre class="hljs"><code>${str}</code></pre>`;
      }
    }
  });

  // function to handle edit button
  const editButton = () => {
    if (item.authorID  === auth.currentUser.uid) {
      return <a href={`/library/apiupload/${id}`}><button className='button button-white'>Edit</button></a>
    }
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!item) {
    return <div>No item found</div>;
  }

  return (
    <div className="library-container-big">
      <div className="library-header">
        <Link to="/library/api" style={{ textDecoration: 'none', color: 'blue' }}>← Back to API Library</Link>
      {editButton()}
      </div>
        
      <div className="library-header">
      <h1>{item.name}</h1>
      <button onClick={handleLike} disabled={isLiking || hasLiked} className='button button-white'>
          {hasLiked ? `Liked ${item.likes || 0}` : (isLiking ? 'Liking...' : `Like ${item.likes || 0}`)}
        </button>
       
      </div>
      <p>Uploaded by: {item.author}</p>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>        
        
      
      {/* Commented out star rating section for future reference
      <span style={{ marginLeft: '20px' }}>
            <StarRating rating={item.rating} />
        <span style={{ marginLeft: '5px' }}>({item.rating})</span>
      </span>
      */}
      </div>
      {/* Sanitize markdown-rendered HTML before inserting it */}
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mdParser.render(item.desc)) }} />

      {item.imageURL && (
        <img src={item.imageURL} alt={item.name} style={{ maxWidth: '100%', marginBottom: '20px' }} />
      )}
      
      
    </div>
  );
};

export default ApiItem;