import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

// Function to fetch grantKey and orgId from Firestore
const fetchGrantKeyAndOrgId = async (userId) => {
  const userDocRef = doc(db, "users", userId);
  try {
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      const data = userDocSnap.data();
      return { 
        grantKey: data.jtgrantkey, 
        orgId: data.jtorgid, 
        cfName: data.graphTwoSettingOne || 'Lead Source',
        cfName2: data.graphFiveSettingOne || 'Job Type',
        calendarTaskType: data.calendarTaskType || 'Pay Phase' // Add calendar task type
      };
    } else {
      console.log("No such document!");
      return { 
        grantKey: 'defaultGrantKey', 
        orgId: 'defaultOrgId', 
        cfName: 'Lead Source',
        cfName2: 'Lead Source 2',
        calendarTaskType: 'Pay Phase'
      };
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    return { 
      grantKey: 'defaultGrantKey', 
      orgId: 'defaultOrgId', 
      cfName: 'Lead Source',
      calendarTaskType: 'Pay Phase'
    };
  }
};

export const fetchTasksQuery = async (userId, startDate, endDate) => {
    const { grantKey, orgId, calendarTaskType } = await fetchGrantKeyAndOrgId(userId);
    return {

    "query": {
      "$": { "grantKey": grantKey }, // Include the grant key in the query object
      "organization": {
        "$": {
          "id": orgId
        },
        "id": {},
        "tasks": {
          "$": {
            "where": {
              "and": [
                [["taskType", "name"], calendarTaskType],
                ["startDate", ">=", startDate],
                ["startDate", "<=", endDate]
              ]
            },
            "sortBy": [
              {
                "field": "startDate"
              }
            ],
            "size": 50,
          },
          "nodes": {
            "id": {},
            "name": {},
            "startDate": {},
            "description": {}
          }
        }
      }
    }
  }
  };

  export const fetchQueryGraphOne = async (userId, startDate, endDate) => {
    const { grantKey, orgId } = await fetchGrantKeyAndOrgId(userId);
    return {

    "query": {
      "$": { "grantKey": grantKey }, // Include the grant key in the query object
      "organization": {
        "$": {
          "id": orgId
        },
        "id": {},
        "documents": {
      "$": {
        "page": "",
        "where": {
          "and": [
            [
              "type",
              "customerOrder"
            ],
            [
              "status",
              "approved"
            ],
            [
              "issueDate",
              ">=",
              startDate
            ],
            [
              "issueDate",
              "<=",
              endDate
            ]
          ]
        },
        "sortBy": [
          {
            "field": "price",
            "order": "desc"
          }
        ],
        "size": 20
      },
      "nodes": {
        "id": {},
        "status": {},
        "issueDate": {},
        "cost": {},
        "price": {},
        "tax": {},
        "name": {},
        "number": {},
        "job": {
          "id": {},
          "name": {},
          "number": {}
        }
      },
      "nextPage": {},
      "previousPage": {}
    }
  }
  }
  }
};
  
export const fetchQueryGraphOne2 = async (userId, startDate, endDate) => {
  const { grantKey, orgId } = await fetchGrantKeyAndOrgId(userId);
  return {

   "query": {
      "$": { "grantKey": grantKey }, // Include the grant key in the query object
      "organization": {
    "$": {
      "id": orgId
    },
    "id": {},
    "accounts": {
      "$": {
        "where": {
          "and": [
            [
              "type",
              "=",
              "customer"
            ],
            [
              "createdAt",
              ">=",
              startDate
            ],
            [
              "createdAt",
              "<=",
              endDate
            ]
          ]
        },
        "group": {
          "by": [
            "type"
          ],
          "aggs": {
            "count": {
              "count": []
            }
          }
        }
      },
      "withValues": {}
    }
  }
  }
  }
};

// Now update fetchQueryGraphTwo to use cfName
export const fetchQueryGraphTwo = async (userId, startDate, endDate) => {
  const { grantKey, orgId, cfName } = await fetchGrantKeyAndOrgId(userId);
  return {
    "query": {
      "$": { "grantKey": grantKey },
      "organization": {
        "$": {
          "id": orgId
        },
        "id": {},
        "customFields": {
          "$": {
            "where":[
              "name",
              "=",
              cfName 
            ]
          },
          "nodes": {
            "id": {},
            "name": {},
            "targetType": {},
            "customFieldValues": {
              "nodes": {
                "id": {},
                "value": {},
                "createdAt": {}
              },
              "$": {
                "where": {
                  "and": [
                    ["createdAt", ">=", startDate],
                    ["createdAt", "<=", endDate]
                  ]
                }
              }
            }
          }
        }
      }
    }
  };
};

// Now update fetchQueryGraphTwo to use cfName2
export const fetchQueryGraphFive = async (userId, startDate, endDate) => {
  const { grantKey, orgId, cfName2 } = await fetchGrantKeyAndOrgId(userId);
  return {
    "query": {
      "$": { "grantKey": grantKey },
      "organization": {
        "$": {
          "id": orgId
        },
        "id": {},
        "customFields": {
          "$": {
            "where":[
              "name",
              "=",
              cfName2 
            ]
          },
          "nodes": {
            "id": {},
            "name": {},
            "targetType": {},
            "customFieldValues": {
              "nodes": {
                "id": {},
                "value": {},
                "createdAt": {}
              },
              "$": {
                "where": {
                  "and": [
                    ["createdAt", ">=", startDate],
                    ["createdAt", "<=", endDate]
                  ]
                }
              }
            }
          }
        }
      }
    }
  };
};

//query for approved proposals from Dominic
export const fetchQueryGraphFour = async (userId, startDate, endDate) => {
  const { grantKey, orgId } = await fetchGrantKeyAndOrgId(userId);
  return {
    "query": {
      "$": { "grantKey": grantKey },
      "organization": {
    "$": {
      "id": orgId
    },
    "id": {},
    "documents": {
      "$": {
        "where": {
          "and": [
            [
              "name",
              "=",
              "Proposal"
            ],
            [
              "createdAt",
              ">=",
              startDate
            ],
            [
              "createdAt",
              "<=",
              endDate
            ]
          ]
        },
        "group": {
          "by": [
            "status"
          ],
          "aggs": {
            "count": {
              "count": []
            },
            "priceWithTax": {
              "sum": "priceWithTax"
            }
          }
        }
      },
      "withValues": {}
    }
  }
    }
  }
};


//query for gantt chart to grab approved and active jobs
export const fetchQueryGanttOne = async (userId) => {
  const { grantKey, orgId } = await fetchGrantKeyAndOrgId(userId);
  return {
    "query": {
      "$": { "grantKey": grantKey },
        "organization": {
          "$": {
            "id": orgId
          },
          "id": {},
          "jobs": {
            "$": {
              "with": {
                "approvedCustomerOrders": {
                  "_": "documents",
                  "$": {
                    "where": {
                      "and": [
                        [
                          "type",
                          "customerOrder"
                        ],
                        [
                          "status",
                          "approved"
                        ]
                      ]
                    }
                  }
                }
              },
              "size": 30,
              "where": {
                "and": [
                  [
                    "closedOn",
                    null
                  ],
                  [
                    [
                      "approvedCustomerOrders",
                      "count"
                    ],
                    ">",
                    0
                  ]
                ]
              }
            },
            "nodes": {
              "id": {},
              "name": []
            }
          }
        }
      }
    }
};

//query for gantt chart to grab tasks
export const fetchQueryGanttTwo = async (userId, jobId) => {
  const { grantKey, orgId } = await fetchGrantKeyAndOrgId(userId);
  return {
    "query": {
      "$": { "grantKey": grantKey },
        "job": {
          "$": {
            "id": jobId
          },
          "id": {},
          "name": {},
          "tasks": {
            "nodes": {
              "id": {},
              "name": {},
              "startDate": {},
              "endDate": {},
              "job": {
                "name": {},
                "id": {}
              }
            },
            "$": {
              "where": {
                "and": [
                  [
                    "startDate",
                    "!=",
                    null
                  ],
                  [
              "isToDo",
                  "=",
                  "false"
                ]
              ]
            },
              "size": 100
            }
          }
        }
      }
    }
};

// query for printed todo reports
export const fetchQueryPrintTodo = async (userId, membershipIds = [], pageToken = "") => {
  const { grantKey, orgId } = await fetchGrantKeyAndOrgId(userId);
  
  // Base filters that are always applied
  const baseFilters = membershipIds.length > 0 ? [
    ["isToDo", "=", "true"],
    ["completed", "<", "1"],
    [
      [
        "assignedMemberships",
        "count"
      ],
      ">",
        0
      ]
    ] : [
      ["isToDo", "=", "true"],
      ["completed", "<", "1"]
  ];

  // Conditionally add the membership filter
  const membershipFilter = membershipIds.length > 0 ? {
    "assignedMemberships": {
      "$": {
        "where": [
          "id",
          "in",
          membershipIds.map(id => String(id))
        ]
      }
    }
  } : {};

  return {
    "query": {
      "$": { "grantKey": grantKey },
      "organization": {
        "$": { "id": orgId },
        "id": {},
        "tasks": {
          "$": {
            "where": {
              "and": baseFilters 
            },
            "with": membershipFilter, //add membership filter
            "page": pageToken
          },
          "nodes": {
            "id": {},
            "name": {},
            "description": {},
            "job": {
              "id": {},
              "name": {}
            },
            "endDate": {},
            "location": {
              "id": {},
              "name": {}
            },
            "subtasks": {
              "name": {}
            },
            "assignedMemberships": {
              "nodes": {
                "id": {},
                "user": {
                  "id": {},
                  "name": {}
                }
              }
            }
          },
          "nextPage": {},
          "previousPage": {}
        }
      }
    }
  };
};


//search internal users by membership id
export const fetchQueryByMembershipId = async (userId, membershipId) => {
  const { grantKey, orgId } = await fetchGrantKeyAndOrgId(userId);
  return {
    "query": {
      "$": { "grantKey": grantKey },
        "membership": {
        "$": {
          "id": membershipId
        },
        "id": {},
        "user": {
          "id": {},
          "name": {}
        }
      }
    }
  }
};

//search for all internal users
export const fetchQueryAllInternalUsers = async (userId) => {
  const { grantKey, orgId } = await fetchGrantKeyAndOrgId(userId);
  return {
    "query": {
      "$": { "grantKey": grantKey },
      "organization": {
        "$": {
          "id": orgId
        },
        "id": {},
        "memberships": {
          "$": {
            "where": [
              "isInternal",
              "=",
              "true"
            ],
            "size": 100
          },
          "nodes": {
            "id": {},
            "user": {
              "id": {},
              "name": {}
            }
          }
        }
      }
    }
  };
};