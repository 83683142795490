import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';

const LibraryHome = () => {
  return (
    <div className="library-container">
    <div>
      <h1>Template Library</h1>
        <p>Create and share templates for cost groups, tasks, todos, and more.</p>
      <Link to="/library/templates"><button className="button button-white">Go to Template Library</button></Link>
      <hr />
      <h1>Script & API Library</h1>
      <p>Create and share scripts and API queries that you use in your workflows.</p>
        <Link to="/library/api"><button className="button button-white">Go to Script & API Library</button></Link>
      </div>
    </div>
  );
};

export default LibraryHome;