import React from 'react';
import { SignUpButton, SignedIn, SignedOut } from '@clerk/clerk-react';
import '../App.css';

function About() {

  return (
    <div className="about-page">
      <div className="about-header">
      <h1>JT Supercharged</h1>
      <p>This is a companion app for JobTread, the leading construction project management software.</p>
      <p>Data is pulled from <u>your</u> JobTread account to visualize and interact in new ways.</p>
      <SignedOut>
        <SignUpButton mode="modal">
            <button className="button button-secondary">Sign Up</button>
        </SignUpButton>   
      </SignedOut>
      <h2>Current Features</h2>
      </div>
      <div className="about-item">
        <div className="about-item-header"> 
          <span className="freePill pill-right-align">Free</span>
          <h2>Template Library</h2>
        </div>
        <div className="about-item-content">
          <p>Templates for cost groups, tasks, and todos. Made by users, for users.</p>
          <img className="about-image" src={require('../assets/library.png')} alt="Library" />
        </div>
      </div>
      <div className="about-item">
        <div className="about-item-header"> 
          <span className="freePill pill-right-align">Free</span>
          <h2>Scripts & API Library</h2>
        </div>
        <div className="about-item-content">
          <p>Custom scripts to work with the API or Javascript / Python scripts to help inside of automation workflows such as Zapier. Made by users, for users.</p>
          <img className="about-image" src={require('../assets/library.png')} alt="Library" />
        </div>
      </div>
      <div className="about-item">      
        <div className="about-item-header">
          <span className="proPill pill-right-align">Pro</span>
          <h2>Dashboard</h2>
        </div>
        <div className="about-item-content">
          <p>Vizualize your data with bar charts, line charts, and more. Easily see new leads, closed leads, won jobs, lead sources, and more.</p>          
          <img className="about-image" src={require('../assets/barchart.png')} alt="Dashboard" />
        </div>
      </div>
      <div className="about-item">    
        <div className="about-item-header">
          <span className="proPill pill-right-align">Pro</span>
          <h2>Cashflow Calendar</h2>
        </div>
        <div className="about-item-content">
          <p>Connect your tasks and view cashflow daily, weekly, monthly.</p>
          <img className="about-image" src={require('../assets/paycal.png')} alt="Cashflow Calendar" />
        </div>
      </div>
      <div className="about-item">
        <div className="about-item-header">
          <span className="proPill pill-right-align">Pro</span>
          <h2>Inventory</h2>
        </div>
        <div className="about-item-content">
          <p>Basic inventory management integrated into JobTread.</p>
          <img className="about-image" src={require('../assets/inventory.png')} alt="Inventory" />
        </div>
      </div>
      <div className="about-item">
        <div className="about-item-header">
          <span className="proPill pill-right-align">Pro</span>
          <h2>Print Reports</h2>
        </div>
        <div className="about-item-content">
          <p>Print tasks, todos, and more in a readable and usable format.</p>
          <img className="about-image" src={require('../assets/forms.png')} alt="Advanced Forms" />
        </div>
      </div>
      <SignedOut>
        <h2>Get Started</h2>
      <p>Sign up today to experience the full power of our application and take your project management to the next level!</p>
      <SignUpButton mode="modal">
        <button className="button button-secondary">Sign Up</button>
      </SignUpButton>   
      </SignedOut>
    </div>
  );
}

export default About;
