import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Dashboard.css';
import { fetchQueryGraphFive } from './queries';
import { useUser } from "@clerk/clerk-react";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend
} from 'recharts';

const GraphFive = () => {
  const { user } = useUser();
  const [formattedData, setFormattedData] = useState([]);
  const [sums, setSums] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // Helper function to get the current year's date range
  const getYearRange = () => {
    const currentDate = new Date();
    const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
    const endOfYear = new Date(currentDate.getFullYear(), 11, 31);
    return {
      start: startOfYear.toISOString().split('T')[0],
      end: endOfYear.toISOString().split('T')[0]
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const dateRanges = getYearRange();
      let leadSourceCountsPerMonth = {};

      try {
        const query = await fetchQueryGraphFive(user.id, dateRanges.start, dateRanges.end);
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/tasks`, query, {
          headers: { 'Content-Type': 'application/json' }
        });
        //console.log('five: ', response.data);

        if (response.data.organization.customFields.nodes.length > 0) {
          const customFieldsNodes = response.data.organization.customFields.nodes[0];
          const customFieldValues = customFieldsNodes.customFieldValues.nodes;
          countCustomFieldValuesMap(customFieldValues, dateRanges.start, leadSourceCountsPerMonth);
          //console.log('five: ', leadSourceCountsPerMonth);
        }else{
          console.log('no custom fields');
        }

        // Process data for PieChart format
        const formatted = Object.entries(leadSourceCountsPerMonth[dateRanges.start] || {}).map(
          ([name, value]) => ({ name, value })
        );
        
        setFormattedData(formatted); // Set formatted data directly
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
      setIsLoading(false);
    };

    if (user) {
      fetchData();
    }
  }, [user]);

  // Helper function to count lead sources
  const countCustomFieldValuesMap = (customFieldValues, monthLabel, leadSourceCountsPerMonth) => {
    leadSourceCountsPerMonth[monthLabel] = leadSourceCountsPerMonth[monthLabel] || {};

    customFieldValues.forEach(item => {
      const fieldValue = item.value;
      if (fieldValue) {
        leadSourceCountsPerMonth[monthLabel][fieldValue] = (leadSourceCountsPerMonth[monthLabel][fieldValue] || 0) + 1;
      }
    });
  };

  // Color palette for the Pie chart segments
  const colors = [
    '#FF6B6B', '#4ECDC4', '#6BCB77', '#4D96FF', '#FF9F45',
    '#845EC2', '#FF5E78', '#00C9A7', '#C34A36', '#008F7A',
    '#FFC75F', '#4B4453', '#B8405E', '#2D6187'
  ];

  return (
    <div className='dashwidget' style={{ gridColumn: 'span 2' }}>      
        <ResponsiveContainer width="100%" height={400}>
          <PieChart>
            <Pie
              data={formattedData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
            >
              {formattedData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>      
    </div>
  );
};

export default GraphFive;
