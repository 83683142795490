import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';
import GraphOne from './GraphOne';
import GraphTwo from './GraphTwo';
import GraphThree from './GraphThree';
import GraphFour from './GraphFour';
import GraphFive from './GraphFive';
import './Dashboard.css';

function Dashboard() {
    const { isLoaded, userId } = useAuth();
    
    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    if (!userId) {
        // Use navigate function directly instead of returning a component
        return <Navigate to="/sign-in" replace={true} />;
    }

    return (
        <div>
            <div className='dashHeader'>
                <div className='dashTitle'>Dashboard</div>
                <div className='dashSubTitle'>Trailing 12 months ending <u>{new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</u></div>
            </div>
            <GraphThree />
            <GraphOne />  
            <div className='widgetRow'>
                <GraphFour /><GraphFive />
            </div>            
            <GraphTwo /> 
        </div>
    );
}

export default Dashboard;
