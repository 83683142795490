import React, { useState, useEffect } from 'react';
import { doc, addDoc, updateDoc, getDoc, collection, deleteDoc } from 'firebase/firestore';
import { signInAnonymously } from 'firebase/auth';
import { db, auth } from '../firebase';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import MarkdownIt from 'markdown-it';
import 'highlight.js/styles/rainbow.css';
import hljs from 'highlight.js';
import '../App.css';

const ApiUpload = () => {
  const { id } = useParams(); // To capture the document ID from URL
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { user } = useUser();
  const [isEditing, setIsEditing] = useState(false); // State to check if editing

  useEffect(() => {
    const authenticateAnonymously = async () => {
      try {
        await signInAnonymously(auth);
      } catch (error) {
        console.error("Error during anonymous authentication:", error);
        setError('Failed to authenticate. Please try again.');
      }
    };

    authenticateAnonymously();

    // Fetch the document if an id exists (for editing)
    if (id) {
      const fetchDocument = async () => {
        try {
          const docRef = doc(db, 'apidocs', id);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setName(data.name); // Prefill form fields
            setDescription(data.desc);
            setIsEditing(true); // Set the editing mode
          } else {
            setError('Document not found');
          }
        } catch (error) {
          console.error("Error fetching document:", error);
          setError('Failed to fetch document. Please try again.');
        }
      };

      fetchDocument();
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);
    setError('');

    try {
      if (isEditing) {
        // Update existing document
        const docRef = doc(db, 'apidocs', id);
        await updateDoc(docRef, {
          name,
          desc: description,
        });
        navigate(`/library/apiupload/${id}`);
      } else {
        // Create new document
        const docRef = await addDoc(collection(db, 'apidocs'), {
          name,
          authorID: auth.currentUser.uid,
          desc: description,
          author: user ? user.fullName : 'Anonymous', 
          imageURL: null,
          rating: 0,
          likes: 0,
          createdAt: new Date()
        });
        navigate(`/library/api/${docRef.id}`);
      }
    } catch (error) {
      console.error("Error uploading or updating document:", error);
      setError('An error occurred. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  const mdParser = new MarkdownIt({
    highlight: (str, lang) => {
      let languageLabel = lang ? lang.toUpperCase() : 'CODE';
      let highlightedCode = '';

      if (lang && hljs.getLanguage(lang)) {
        try {
          highlightedCode = hljs.highlight(str, { language: lang }).value;
        } catch (__) {
          highlightedCode = hljs.highlightAuto(str).value;
        }
      } else {
        highlightedCode = hljs.highlightAuto(str).value;
      }

      return `
        <div>
        <div class="code-header">${languageLabel}</div>
        <pre class="hljs">${highlightedCode}</pre>
        </div>
      `;
    }
  });

  function handleEditorChange({ html, text }) {
    setDescription(text);
    console.log('handleEditorChange', html, text);
  }

  const handleDelete = async () => {
    const confirmDelete = window.confirm("Are you sure you want to delete this document? This action is permanent.");
    if (!confirmDelete) {
      return; // Exit the function if the user cancels the deletion
    }

    try {
      const docRef = doc(db, 'apidocs', id);
      await deleteDoc(docRef);
      navigate('/library/api');
    } catch (error) {
      console.error("Error deleting document:", error);
      setError('Failed to delete document. Please try again.');
    }
  };

  return (
    <div>
      <Link to="/library/api" style={{ textDecoration: 'none', color: 'blue' }}>← Back to API Library</Link>
      <h2>{isEditing ? 'Edit API Doc' : 'Write New API Doc'}</h2>
      <form onSubmit={handleSubmit}>
        <button style={{ marginBottom: '30px' }} className='button button-white' type="submit" disabled={uploading}>
          {uploading ? 'Saving...' : isEditing ? 'Update' : 'Upload'}
        </button>
        
        {isEditing && (
          <button
            type="button"
            className='button button-danger'
            onClick={handleDelete}
            style={{ marginLeft: '10px' }}
          >
            Delete
          </button>
        )}
        
        <div>
          <label htmlFor="name">Document Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="description">Description:</label>
          <MdEditor
            id="description"
            style={{ height: '600px' }}
            renderHTML={text => mdParser.render(text)}
            onChange={handleEditorChange}
            value={description}  // Prefill the editor with existing description for editing
          />
        </div>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </form>
    </div>
  );
};

export default ApiUpload;
