import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, deleteField, collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { signInAnonymously } from 'firebase/auth';
import { db, storage, auth } from '../firebase';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';

const LibraryUpload = () => {
  const { id } = useParams(); // Capture the document ID from URL
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [csvFile, setCsvFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [csvFileURL, setCsvFileURL] = useState(null); // URL of existing CSV file
  const [imageURL, setImageURL] = useState(null); // URL of existing image file
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false); // Determine if in edit mode
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    const authenticateAnonymously = async () => {
      try {
        await signInAnonymously(auth);
      } catch (error) {
        console.error("Error during anonymous authentication:", error);
        setError('Failed to authenticate. Please try again.');
      }
    };

    authenticateAnonymously();

    if (id) {
      // Fetch existing document if editing
      const fetchDocument = async () => {
        try {
          const docRef = doc(db, 'library', id);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setName(data.name);
            setDescription(data.desc);
            setCsvFileURL(data.csvFileURL || null); // Set existing CSV URL
            setImageURL(data.imageURL || null); // Set existing image URL
            setIsEditing(true);  // Set edit mode to true
          } else {
            setError('Document not found');
          }
        } catch (error) {
          console.error("Error fetching document:", error);
          setError('Failed to fetch document. Please try again.');
        }
      };

      fetchDocument();
    }
  }, [id]);

  const handleCsvFileChange = (e) => {
    if (e.target.files[0] && e.target.files[0].name.endsWith('.csv')) {
      setCsvFile(e.target.files[0]);
      setError('');
    } else {
      setCsvFile(null);
      setError('Please select a valid CSV file.');
    }
  };

  const handleImageFileChange = (e) => {
    if (e.target.files[0] && e.target.files[0].type.startsWith('image/')) {
      setImageFile(e.target.files[0]);
      setError('');
    } else {
      setImageFile(null);
      setError('Please select a valid image file.');
    }
  };

  const deleteExistingFile = async (fileType) => {
    try {
      const fileRef = ref(storage, fileType === 'csv' ? csvFileURL : imageURL);
      await deleteObject(fileRef); // Delete file from Firebase Storage

      // Update Firestore document to remove the file URL
      const docRef = doc(db, 'library', id);
      await updateDoc(docRef, {
        [fileType === 'csv' ? 'csvFileURL' : 'imageURL']: deleteField(),
      });

      if (fileType === 'csv') {
        setCsvFileURL(null);
      } else {
        setImageURL(null);
      }

      alert(`${fileType.toUpperCase()} file deleted successfully.`);
    } catch (error) {
      console.error("Error deleting file:", error);
      setError('An error occurred while deleting the file. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);
    setError('');

    try {
      let newCsvFileURL = csvFileURL;
      let newImageURL = imageURL;

      // Upload CSV file if new file selected
      if (csvFile) {
        const csvStorageRef = ref(storage, `library/csv/${Date.now()}_${csvFile.name}`);
        await uploadBytes(csvStorageRef, csvFile);
        newCsvFileURL = await getDownloadURL(csvStorageRef);
      }

      // Upload Image file if new file selected
      if (imageFile) {
        const imageStorageRef = ref(storage, `library/images/${Date.now()}_${imageFile.name}`);
        await uploadBytes(imageStorageRef, imageFile);
        newImageURL = await getDownloadURL(imageStorageRef);
      }

      if (isEditing) {
        // Update existing document
        const docRef = doc(db, 'library', id);
        await updateDoc(docRef, {
          name,
          desc: description,
          csvFileURL: newCsvFileURL,
          imageURL: newImageURL,
          updatedAt: new Date(),
        });
      } else {
        // Add new document
        const docRef = await addDoc(collection(db, 'library'), {
          name,
          desc: description,
          author: user ? user.fullName : 'Anonymous',
          csvFileURL: newCsvFileURL,
          imageURL: newImageURL,
          rating: 0,
          downloads: 0,
          createdAt: new Date(),
        });
        navigate(`/library/templates/${docRef.id}`);
      }
      setUploading(false);
      navigate(`/library/templates/${id}`);
    } catch (error) {
      console.error("Error uploading files or updating document:", error);
      setUploading(false);
      setError('An error occurred while uploading. Please try again.');
    }
  };

  return (
    <div>
      <Link to="/library" style={{ textDecoration: 'none', color: 'blue' }}>← Back to Library</Link>
      <h2>{isEditing ? 'Edit Library Item' : 'Upload New Library Item'}</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>

        {/* Display and delete option for CSV file */}
        <div>
          <label htmlFor="csvFile">Template File (CSV only):</label>
          {csvFileURL ? (
            <div>
              <a href={csvFileURL} target="_blank" rel="noopener noreferrer">View CSV File</a><br />
              <button type="button" onClick={() => deleteExistingFile('csv')}>Delete CSV</button>
              <br /><br />
            </div>
          ) : (
            <input type="file" id="csvFile" accept=".csv" onChange={handleCsvFileChange} />
          )}
        </div>

        {/* Display and delete option for Image file */}
        <div>
          <label htmlFor="imageFile">Image (optional):</label>
          {imageURL ? (
            <div>
              <a href={imageURL} target="_blank" rel="noopener noreferrer">View Image</a><br />
              <button type="button" onClick={() => deleteExistingFile('image')}>Delete Image</button>
              <br /><br />
            </div>            
          ) : (
            <input type="file" id="imageFile" accept="image/*" onChange={handleImageFileChange} />
          )}
        </div>

        {error && <p style={{ color: 'red' }}>{error}</p>}
        <button type="submit" disabled={uploading}>
          {uploading ? 'Uploading...' : isEditing ? 'Save Changes' : 'Upload'}
        </button>
      </form>
    </div>
  );
};

export default LibraryUpload;
