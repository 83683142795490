import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { db } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';
import '../App.css';

const StarRating = ({ rating }) => (
  <span className="library-item-rating">
    {'★'.repeat(Math.floor(rating))}
    {rating % 1 >= 0.5 ? '½' : ''}
    {'☆'.repeat(5 - Math.ceil(rating))}
    ({rating.toFixed(1)})
  </span>
);

const DownloadIcon = () => (
  <span style={{ fontSize: '1.2em', marginRight: '5px' }}>⬇️</span>
);

const Library = () => {
  const [libraryItems, setLibraryItems] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLibrary = async () => {
      try {
        const libraryCollection = collection(db, 'library');
        const librarySnapshot = await getDocs(libraryCollection);
        const items = librarySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        // Sort items by downloads in descending order
        const sortedItems = items.sort((a, b) => b.downloads - a.downloads);
        setLibraryItems(sortedItems);
      } catch (error) {
        console.error("Error fetching library:", error);
        setError(error.message);
      }
    };

    fetchLibrary();
  }, []);

  const handleUpload = () => {
    navigate('/upload');
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleDownload = async (item) => {
    try {
      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = item.csvFileURL;
      link.download = item.name || 'download'; // Use item name as filename, or 'download' if not available
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      // Update the download counter in the database
      const docRef = doc(db, 'library', item.id);
      await updateDoc(docRef, {
        downloads: increment(1)
      });
      
      // Update the local state
      setLibraryItems(prevItems => prevItems.map(prevItem => 
        prevItem.id === item.id 
          ? { ...prevItem, downloads: prevItem.downloads + 1 }
          : prevItem
      ));
    } catch (error) {
      console.error("Error downloading file or updating download count:", error);
    }
  };

  return (
    <div className="library-container">
      <div className="library-header">
        <h1>Template Library</h1>
        <button className="button button-white" onClick={handleUpload}>+ Upload New Item</button>
      </div>
      <div className="library-items">
        {libraryItems.map(item => (
          <div key={item.id} className="library-item">
            <div className="library-item-header">
              <h3 className="library-item-title">{item.name}</h3>
              <div className="library-item-metrics">
                
                <span className="library-item-downloads">
                  <DownloadIcon /> {item.downloads}
                </span>
              </div>
            </div>
            <p className="library-item-author">Created by: {item.author}</p>
            <p className="library-item-description">{item.desc.substring(0, 150)}...</p>
            <div className="library-item-actions">
              <a href={`/library/templates/${item.id}`}><button className="button button-white">
                Read More
              </button></a>
              <button onClick={() => handleDownload(item)} className="button button-white">
                Download File
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Library;