import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, Brush } from 'recharts';
import './Dashboard.css';
import { fetchQueryGraphFour, fetchQueryGraphOne2 } from './queries';
import { useUser } from "@clerk/clerk-react";

const GraphOne = () => {
  const { user } = useUser();
  const [weeklyDocsCount, setWeeklyDocsCount] = useState([0, 0, 0, 0, 0, 0, 0, 0]); // Default values
  const [labels, setLabels] = useState([]);
  const [newlabels, setNewLabels] = useState([]);
  const [monthLeadsCount, setMonthLeadsCount] = useState([0, 0, 0, 0, 0, 0, 0, 0]); // Default values
  const [draftsCount, setDraftsCount] = useState([0, 0, 0, 0, 0, 0, 0, 0]); // Default values
  const [deniedCount, setDeniedCount] = useState([0, 0, 0, 0, 0, 0, 0, 0]); // Default values
  const [pendingCount, setPendingCount] = useState([0, 0, 0, 0, 0, 0, 0, 0]); // Default values
  const [approvedAmounts, setApprovedAmounts] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [draftAmounts, setDraftAmounts] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [deniedAmounts, setDeniedAmounts] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [pendingAmounts, setPendingAmounts] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [isLoading, setIsLoading] = useState(false);

  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];

  //get start and end date for each week, starting today, going back 8 weeks
  const getDateRanges = () => {
    const rangesWeek = [];
    let currentDate = new Date();
    currentDate = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay())); // Move to start of this week
  
    for (let i = 0; i < 8; i++) {
      const endOfWeek = new Date(currentDate);
      const startOfWeek = new Date(currentDate.setDate(currentDate.getDate() - 6)); // Move back to the start of the week
      rangesWeek.unshift({
        start: startOfWeek.toISOString().split('T')[0], // Format as YYYY-MM-DD
        end: endOfWeek.toISOString().split('T')[0]
      });
      currentDate = new Date(startOfWeek.setDate(startOfWeek.getDate() - 1)); // Move to the day before the start of last week
    }
    return rangesWeek;
  };

  //get start and end date for each month, starting today, going back 8 months
  const getMonthRanges = () => {
    const rangesMonth = [];
    let currentDate = new Date();  // Start from today
  
    for (let i = 0; i < 12; i++) {
      const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1); // First day of the current month
      const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0); // Last day of the current month
  
      rangesMonth.unshift({
        start: startOfMonth.toISOString().split('T')[0], // Format as YYYY-MM-DD
        end: endOfMonth.toISOString().split('T')[0]
      });
  
      currentDate = new Date(startOfMonth.setDate(startOfMonth.getDate() - 1)); // Move to the last day of the previous month
    }
  
    return rangesMonth;
  };

  // takes the month names and overlays into an array based on month ranges
  const transformDateLabelsToMonths = (labels) => {
    return labels.map(label => {
      const date = new Date(`${label}T00:00:00Z`);
      const monthIndex = date.getUTCMonth(); // Use getUTCMonth to avoid timezone issues
      return monthNames[monthIndex];  // Map the month index to month name
    });
  };
  
  // FIRST USE EFFECT FUNCTION
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const dateRanges = getMonthRanges();
      const labelsTemp = dateRanges.map(range => `${range.start}`);
      const countsJobs = [];
      const countsApprovedAmount = [];
      const countsLeads = [];
      const countsDrafts = [];
      const countsDraftAmount = [];
      const countsDenied = [];
      const countsDeniedAmount = [];
      const countsPending = [];
      const countsPendingAmount = [];
  
      for (const range of dateRanges) {
        try {
          const query1 = await fetchQueryGraphFour(user.id, range.start, range.end);
          const response1 = await axios.post(`${process.env.REACT_APP_API_URL}/tasks`, query1, {
            headers: { 'Content-Type': 'application/json' }
          });
          
          // checks for approved documents
          const approvedDoc = response1.data?.organization?.documents?.withValues?.find(
            doc => doc.status === "approved"
          );
          const approvedCount = approvedDoc?.count || 0;
          countsJobs.push(approvedCount);
          const approvedAmount = approvedDoc?.priceWithTax || 0;
          countsApprovedAmount.push(approvedAmount);

          // checks for draft documents
          const draftDoc = response1.data?.organization?.documents?.withValues?.find(
            doc => doc.status === "draft"
          );
          const draftCount = draftDoc?.count || 0;
          countsDrafts.push(draftCount);
          const draftAmount = draftDoc?.priceWithTax || 0;
          countsDraftAmount.push(draftAmount);

          // checks for denied documents
          const deniedDoc = response1.data?.organization?.documents?.withValues?.find(
            doc => doc.status === "denied"
          );
          const deniedCount = deniedDoc?.count || 0;
          countsDenied.push(deniedCount);
          const deniedAmount = deniedDoc?.priceWithTax || 0;
          countsDeniedAmount.push(deniedAmount);

          // checks for pending documents
          const pendingDoc = response1.data?.organization?.documents?.withValues?.find(
            doc => doc.status === "pending"
          );
          const pendingCount = pendingDoc?.count || 0;
          countsPending.push(pendingCount);
          const pendingAmount = pendingDoc?.priceWithTax || 0;
          countsPendingAmount.push(pendingAmount);

          // query for leads aka new customers created
          const query2 = await fetchQueryGraphOne2(user.id, range.start, range.end);
          const response2 = await axios.post(`${process.env.REACT_APP_API_URL}/tasks`, query2, {
            headers: { 'Content-Type': 'application/json' }
          });
          
          // Add null checks and logging
          const leadsCount = response2.data?.organization?.accounts?.withValues?.[0]?.count || 0;
          countsLeads.push(leadsCount);

        } catch (error) {
          console.error('Error fetching documents:', error);
          countsJobs.push(0); // Default value in case of error
          countsLeads.push(0); // Default value in case of error
          countsDrafts.push(0); // Default value in case of error
          countsDenied.push(0); // Default value in case of error
          countsPending.push(0); // Default value in case of error
          
        }
      }
  
      setLabels(labelsTemp);
      setWeeklyDocsCount(countsJobs);
      setApprovedAmounts(countsApprovedAmount);
      setDraftAmounts(countsDraftAmount);
      setDeniedAmounts(countsDeniedAmount);
      setPendingAmounts(countsPendingAmount); 
      setMonthLeadsCount(countsLeads);
      setDraftsCount(countsDrafts);
      setDeniedCount(countsDenied);
      setPendingCount(countsPending);
      setIsLoading(false);
    };
  
    if (user) {
      fetchData();
    }
  }, [user]);
  
  // Use an effect to transform labels once they are set
  useEffect(() => {
    const newLabels = transformDateLabelsToMonths(labels);
    setNewLabels(newLabels);
  }, [labels]); // Dependency on labels ensures transformation occurs after labels are updated
  

  const monthlyDiff = ( a, b ) => {
    const diff = (((a - b)/b)*100);
    const diff2 = diff.toPrecision(2);
    return diff2;
  };

  const totalDocs = () => {
    let total = 0;
    return total;
  };
  const createData = (labels, uvData, dvData, dvData2, dvData3) => {
    return labels.map((label, index) => ({
      name: label,
      approved: uvData[index],
      drafts: dvData[index],
      denied: dvData2[index],
      pending: dvData3[index]
    }));
  };
  
  const data1 = createData(newlabels, weeklyDocsCount, draftsCount, deniedCount, pendingCount);
  
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount);
  };

  return (
    <div className='widgetRow'>
        <div className='widgetStack'>
        <div className='dashwidget'>
            <div className='widgetSubTitle'>Approved</div><hr />
            <div className='widgetNumber'>{weeklyDocsCount[11] || 0}</div>
            <div className='widgetSubTitle'>{monthlyDiff(weeklyDocsCount[11] || 0, weeklyDocsCount[10] || 0)}% to last month</div>
            <hr />
            <div className='widgetTitle'>{formatCurrency(approvedAmounts[11] || 0)}</div>
            <div className='widgetSubTitle'>this month</div>
        </div>
        <div className='dashwidget'>
            <div className='widgetSubTitle'>Pending</div><hr />
            <div className='widgetNumber'>{pendingCount[11] || 0}</div><hr />
            <div className='widgetTitle'>{formatCurrency(pendingAmounts[11] || 0)}</div>
            <div className='widgetSubTitle'>this month</div></div>
        <div className='dashwidget'>
        <div className='widgetSubTitle'>Total Estimates</div><hr /> 
          <div className='widgetNumber'>{deniedCount[11] + pendingCount[11] + weeklyDocsCount[11] + draftsCount[11] || 0}</div>
          <div className='widgetSubTitle'>{monthlyDiff(deniedCount[11] + pendingCount[11] + weeklyDocsCount[11] + draftsCount[11] || 0, deniedCount[10] + pendingCount[10] + weeklyDocsCount[10] + draftsCount[10] || 0)}% to last month</div>
          <hr />
          <div className='widgetTitle'>{formatCurrency(deniedAmounts[11] + pendingAmounts[11] + approvedAmounts[11] + draftAmounts[11] || 0)}</div>
          <div className='widgetSubTitle'>this month</div></div>
        </div>
        <div className='dashwidget' style={{ gridColumn: 'span 3' }}>          
        <ResponsiveContainer width="100%" height="100%" initialDimension={{ width: 520, height: 400 }}>
          <LineChart width={400} height={400} data={data1} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
          
            <Line type="monotone" dataKey="approved" stroke="#2ecc71" />
            <Line type="monotone" dataKey="drafts" stroke="#0088fe" />
            <Line type="monotone" dataKey="pending" stroke="#ff7300" />
            <Line type="monotone" dataKey="denied" stroke="#777777" />
            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Brush dataKey="name" height={20} />
          </LineChart>
        </ResponsiveContainer>
        </div>
    </div>
  );
}

export default GraphOne;
