import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from "@clerk/clerk-react";
import { fetchQueryGraphFour } from './queries';
import './Dashboard.css';

const GraphThree = () => {
  const { user } = useUser();
  const [approvedCount, setApprovedCount] = useState(0); // Default values
  const [labels, setLabels] = useState([]);
  const [newlabels, setNewLabels] = useState([]);
  const [draftsCount, setDraftsCount] = useState(0); // Default values
  const [deniedCount, setDeniedCount] = useState(0); // Default values
  const [pendingCount, setPendingCount] = useState(0); // Default values
  const [isLoading, setIsLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [approvedTotalAmount, setApprovedTotalAmount] = useState(0);
  const [pendingTotalAmount, setPendingTotalAmount] = useState(0);
  const [draftTotalAmount, setDraftTotalAmount] = useState(0);
  const [deniedTotalAmount, setDeniedTotalAmount] = useState(0);

  //get year range
  const getDateRanges = () => {
    const currentDate = new Date();
    
    // Current year range
    const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
    const endOfYear = new Date(currentDate.getFullYear(), 11, 31);
    
    // Trailing 12 months
    const startOfTrailing12 = new Date(currentDate);
    startOfTrailing12.setMonth(currentDate.getMonth() - 11); // Go back 11 months
    startOfTrailing12.setDate(1); // First day of that month
    
    return {
      trailing12: {
        start: startOfTrailing12.toISOString().split('T')[0],
        end: currentDate.toISOString().split('T')[0]
      }
    };
  };
  
  // FIRST USE EFFECT FUNCTION
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const dateRange = getDateRanges(); // Now returns a single object, not an array
      
      try {
        const query = await fetchQueryGraphFour(user.id, dateRange.trailing12.start, dateRange.trailing12.end);
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/tasks`, query, {
          headers: { 'Content-Type': 'application/json' }
        });
        
        // checks for approved documents
        const approvedDoc = response.data?.organization?.documents?.withValues?.find(
          doc => doc.status === "approved"
        );
        const approvedCount = approvedDoc?.count || 0;
        const approvedTotalAmount = approvedDoc?.priceWithTax || 0;

        // checks for draft documents
        const draftDoc = response.data?.organization?.documents?.withValues?.find(
          doc => doc.status === "draft"
        );
        const draftCount = draftDoc?.count || 0;
        const draftTotalAmount = draftDoc?.priceWithTax || 0;

        // checks for denied documents
        const deniedDoc = response.data?.organization?.documents?.withValues?.find(
          doc => doc.status === "denied"
        );
        const deniedCount = deniedDoc?.count || 0;
        const deniedTotalAmount = deniedDoc?.priceWithTax || 0;

        // checks for pending documents
        const pendingDoc = response.data?.organization?.documents?.withValues?.find(
          doc => doc.status === "pending"
        );
        const pendingCount = pendingDoc?.count || 0;
        const pendingTotalAmount = pendingDoc?.priceWithTax || 0;

        setApprovedCount(approvedCount);
        setDraftsCount(draftCount);
        setDeniedCount(deniedCount);
        setPendingCount(pendingCount);
        setApprovedTotalAmount(approvedTotalAmount);
        setPendingTotalAmount(pendingTotalAmount);
        setDraftTotalAmount(draftTotalAmount);
        setDeniedTotalAmount(deniedTotalAmount);
        setTotalAmount(approvedTotalAmount + pendingTotalAmount + draftTotalAmount + deniedTotalAmount);

      } catch (error) {
        console.error('Error fetching documents:', error);
        setApprovedCount(0);
        setDraftsCount(0);
        setDeniedCount(0);
        setPendingCount(0);
        setTotalAmount(0);
      }
      
      setIsLoading(false);
    };
  
    if (user) {
      fetchData();
    }
  }, [user]);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount);
  };

  const totalCount = approvedCount + pendingCount + deniedCount + draftsCount;

  return (
    <div className='widgetRow'>
            <div className='dashwidget'>
            <div className='widgetSubTitle'>Approved</div><hr />
                <div className='widgetNumber'>{approvedCount || 0}</div><hr />
                <div className='widgetTitle'>{formatCurrency(approvedTotalAmount)}</div><hr />
                <div className='widgetSubTitle'>{totalCount ? ((approvedCount / totalCount) * 100).toFixed(2) : 0}% of total</div>
            </div>
            <div className='dashwidget'>
              <div className='widgetSubTitle'>Pending</div><hr />
              <div className='widgetNumber'>{pendingCount || 0}</div><hr />
              <div className='widgetTitle'>{formatCurrency(pendingTotalAmount)}</div>
            </div>
            <div className='dashwidget'>
              <div className='widgetSubTitle'>In Draft</div><hr />
              <div className='widgetNumber'>{draftsCount || 0}</div><hr />
              <div className='widgetTitle'>{formatCurrency(draftTotalAmount)}</div>
            </div>
            <div className='dashwidget'>
              <div className='widgetSubTitle'>Total Estimates</div><hr />
              <div className='widgetNumber'>{totalCount || 0}</div><hr />
                <div className='widgetTitle'>{formatCurrency(totalAmount)}</div>
            </div>
        </div>
  );
}

  export default GraphThree;
