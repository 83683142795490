import React, { useState, useEffect } from "react";
import axios from "axios";
import { useUser } from "@clerk/clerk-react";
import { fetchGrantKeyAndOrgId,fetchQueryAllInternalUsers, fetchQueryPrintTodo } from "./queries";

const PrintReports = () => {
  const { user } = useUser();
  const [output, setOutput] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [internalUsers, setInternalUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch internal users
  const fetchInternalUsers = async () => {
    setLoading(true);
    setError(null);
    try {
      const query = await fetchQueryAllInternalUsers(user.id);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/tasks`, query, {
        headers: { "Content-Type": "application/json" },
      });
      setInternalUsers(response.data.organization.memberships.nodes);
    } catch (err) {
      //console.error("Error fetching internal users:", err);
      setError("Failed to fetch internal users");
    } finally {
      setLoading(false);
    }
  };

  // Fetch data for output, with optional assignee filtering
  const handleQuery = async (assignees = []) => {
    let allData = [];
    let nextPageToken = "";
    let hasMorePages = true;

    try {
      // Make initial API call
      const query = await fetchQueryPrintTodo(user.id, assignees, nextPageToken);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/tasks`, query, {
        headers: { "Content-Type": "application/json" },
      });
      
      // Process initial response
      allData = [...response.data.organization.tasks.nodes];
      nextPageToken = response.data.organization.tasks.nextPage;
      hasMorePages = !!nextPageToken;
      
      // Fetch remaining pages if they exist
      while (hasMorePages) {
        const nextQuery = await fetchQueryPrintTodo(user.id, assignees, nextPageToken);
        const nextResponse = await axios.post(`${process.env.REACT_APP_API_URL}/tasks`, nextQuery, {
          headers: { "Content-Type": "application/json" },
        });
        
        // Append current page's nodes to allData array
        allData = [...allData, ...nextResponse.data.organization.tasks.nodes];
        
        // Update nextPageToken and check if there are more pages
        nextPageToken = nextResponse.data.organization.tasks.nextPage;
        hasMorePages = !!nextPageToken;
      }

      setOutput({ organization: { tasks: { nodes: allData } } });
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  // Handle checkbox changes for assignees
  const handleAssigneeChange = (assigneeId) => {
    setSelectedAssignees((prev) => {
      const updatedAssignees = prev.includes(assigneeId)
        ? prev.filter((id) => id !== assigneeId) // Unselect assignee
        : [...prev, assigneeId]; // Select assignee

      // Trigger API query with the updated assignees
      handleQuery(updatedAssignees);
      //console.log("Updated assignees:", updatedAssignees);
      return updatedAssignees;
    });
  };

  useEffect(() => {
    if (user) {
      fetchInternalUsers();
      handleQuery(); // Initial query without filters
    }
  }, [user]);

  const handlePrint = () => {
    // Get the content of the print-output div
    const printContent = document.getElementById('print-output').innerHTML;
    const printWindow = window.open('', '_blank');
    const cssPath = `${process.env.PUBLIC_URL}/App.css`;
    
    // Write the HTML for printing
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <link rel="stylesheet" type="text/css" href="${cssPath}">
        </head>
        <style>
            body { font-family: Arial, sans-serif; margin: 20px; }
            .library-item { 
              padding: 10px; 
              border-bottom: 1px solid #ddd;
              page-break-inside: avoid; /* Prevent page breaks within items */
              break-inside: avoid; /* Modern browsers */
            }
            @media print {
              .library-item {
                break-inside: avoid-page;
                page-break-inside: avoid;
              }
            }
          </style>
        <body onload="window.print(); window.close();">
          <div>${printContent}</div>
        </body>
      </html>
    `);
    printWindow.document.close();
  };  

  return (
    <div style={{ display: "flex", padding: "20px" }}>
      {/* Left Panel: API Query Input */}
      <div style={{ flex: 1, paddingRight: "20px", marginRight: "20px" }}>
        <h3>Print ToDo Settings</h3>
        <div style={{ marginBottom: "20px" }}>
          <label>Select Assignees:</label>
          {loading ? (
            <p>Loading assignees...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <div className="library-item" style={{ display: "flex", flexDirection: "column", padding: "0px" }}>
              {internalUsers.map((membership) => (
                <div key={membership.id} style={{ display: "flex", alignItems: "center", padding: "10px", borderBottom: "1px solid #ccc" }}>
                  <input
                    type="checkbox"
                    checked={selectedAssignees.includes(membership.id)}
                    onChange={() => handleAssigneeChange(membership.id)}
                    style={{ marginRight: "8px", width: "25px" }}
                  />
                  <label>{membership.user.name}</label>
                </div>
              ))}
            </div>
          )}
        </div>
        <button onClick={() => handleQuery(selectedAssignees)} className="button button-white">
          Save Settings
        </button>
        <button onClick={() => handlePrint()} className="button button-white">
          Print Report
        </button>
      </div>
  
      {/* Right Panel: Render Output */}
      <div style={{ flex: 1 }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h3>Preview Output</h3>
        </div> 
        <div id="print-output" style={{ marginTop: "20px" }}>
          {output.organization?.tasks?.nodes?.length > 0 ? (
            output.organization.tasks.nodes.map((item, index) => (
              <div key={index} className="library-item">
                <div style={{ paddingBottom: "5px", fontSize: "12px", color: "#666", display: "flex", justifyContent: "space-between" }}>
                  <span>{item.location?.name || ''}</span>
                  <span>{item.job?.name || ''}</span>
                  <span>{item.endDate || ''}</span>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <b>{item.name || ''}</b>
                </div>
                <div style={{ paddingBottom: "5px", fontSize: "12px", color: "#666", display: "flex", justifyContent: "space-between" }}>
                  <span>{item.assignedMemberships?.nodes?.map(node => node.user?.name).join(', ') || ''}</span>
                </div>
                <p>{item.description || ''}</p>
                {item.subtasks?.length > 0 && (
                  <div>
                    <span>Checklist:</span>
                    <ul style={{ listStyleType: "disc", marginTop: "5px" }}>
                      {item.subtasks.map((st, index) => (
                        <li key={index}>{st.name}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))
          ) : (
            <p>No data available. Please submit a query.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrintReports;
