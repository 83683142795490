import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

const GanttChart = ({ tasks, zoomLevel }) => {
  const svgRef = useRef();
  const yAxisRef = useRef();

  const baseWidth = 2500;
  const barHeight = 30;
  const margin = { top: 100, right: 20, bottom: 15, left: 150 };
  const innerHeight = tasks.length * (barHeight + 10);
  const height = innerHeight + margin.top + margin.bottom;
  const dynamicWidth = baseWidth * zoomLevel;

  useEffect(() => {
    const parseDate = d3.timeParse("%Y-%m-%d");
    const formattedTasks = tasks.map(d => ({
      ...d,
      start: parseDate(d.startDate),
      end: parseDate(d.endDate),
    }));
    // Group tasks by name (job name)
    const groupedTasks = d3.group(formattedTasks, d => d.name);
    
    // Create array of unique job names for y-axis
    const jobNames = Array.from(groupedTasks.keys());

    const baseWidth = 2500;
    const barHeight = 20;
    const margin = { top: 100, right: 20, bottom: 15, left: 150 };
    const innerHeight = jobNames.length * (barHeight + 10);
    const height = innerHeight + margin.top + margin.bottom;
    const dynamicWidth = baseWidth * zoomLevel; // Adjust width based on zoom level
  
    const minDate = d3.min(formattedTasks, d => d.start);
    const maxDate = d3.max(formattedTasks, d => d.end);
    const xDomain = [minDate, maxDate];

    const xScale = d3.scaleTime().domain(xDomain).range([0, (baseWidth - margin.left - margin.right) * zoomLevel]);

    // First, ensure we're getting unique task identifiers
    const taskIds = formattedTasks.map(d => `${d.name}`);

    // Update the yScale to properly space out the tasks
    const yScale = d3
      .scaleBand()
      .domain(taskIds)  // Use the array of unique task identifiers
      .range([0, innerHeight])  // This maps to the full height of the chart
      .padding(0.2);  // Add padding between bars

    const colorScale = d3.scaleOrdinal(d3.schemeTableau10);

    // Y-Axis SVG for stationary labels
    const yAxisSvg = d3.select(yAxisRef.current);
    yAxisSvg.selectAll("*").remove();
    yAxisSvg
    .attr("width", margin.left)
    .attr("height", height)
    .append("g")
    .attr("transform", `translate(${margin.left - 10},${margin.top})`)
    .call(d3.axisLeft(yScale).tickSize(0).tickPadding(10))
    .attr("class", "y-axis");

    // Main Chart SVG
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    // Group for zoomable content
    const zoomGroup = svg
    .attr("width", dynamicWidth) // Set SVG width dynamically
    .attr("height", height)
    .append("g")
    .attr("transform", `translate(0,${margin.top})`); // adjusted to be closer to yaxis 0 = left margin

    // Custom X-Axis with Day of Week Labels - moved to top
    const xAxis = zoomGroup.append("g")
    .attr("class", "x-axis")
    .attr("transform", `translate(0,-10)`)
    .call(
        d3.axisBottom(xScale)
        .ticks(d3.timeDay.every(1))
        .tickSize(0)
        .tickFormat((d) => {
            const dateFormat = d3.timeFormat("%d");
            const dayFormat = d3.timeFormat("%a");
            return `${dateFormat(d)}\n${dayFormat(d)}`;
        })
    );

    // Style the domain line
    xAxis.select(".domain")
    .attr("stroke", "#333")        // Change color
    .attr("stroke-width", 1)       // Change thickness
    .attr("transform", "translate(0, 10)"); // Move it up

    // Add daily grid lines (light)
    const dayTicks = d3.timeDay.range(xDomain[0], xDomain[1]);
    zoomGroup.selectAll(".grid-line-day")
    .data(dayTicks)
    .enter()
    .append("line")
    .attr("class", "grid-line-day")
    .attr("x1", d => xScale(d))
    .attr("x2", d => xScale(d))
    .attr("y1", 0)
    .attr("y2", innerHeight)
    .attr("stroke", "#e0e0e0")
    .attr("stroke-width", 1);

    // Add weekly grid lines (dark)
    const weekTicks = d3.timeWeek.range(xDomain[0], xDomain[1]);
    zoomGroup.selectAll(".grid-line-week")
    .data(weekTicks)
    .enter()
    .append("line")
    .attr("class", "grid-line-week")
    .attr("x1", d => xScale(d))
    .attr("x2", d => xScale(d))
    .attr("y1", 0)
    .attr("y2", innerHeight)
    .attr("stroke", "#999")  // Darker color for week lines
    .attr("stroke-width", 1.5);  // Slightly thicker for week lines

    // Calculate tick spacing dynamically
    const tickSpacing = xScale(d3.timeDay.offset(minDate, 1)) - xScale(minDate);
    const centerOffset = tickSpacing / 2; // Center the text between ticks

    // Create a group for the month labels - moved above x-axis
    const monthsGroup = zoomGroup.append("g")
    .attr("class", "months-group")
    .attr("transform", `translate(0,-50)`); // Position above the x-axis (negative value)

    // Define the start and end of each month in the date range
    const months = d3.timeMonths(xDomain[0], xDomain[1]);

    // Create month background rectangles and labels
    const monthGroups = monthsGroup.selectAll(".month-group")
    .data(months)
    .enter()
    .append("g")
    .attr("class", "month-group");

    // Add background rectangles
    monthGroups.append("rect")
    .attr("class", "month-background")
    .attr("x", d => xScale(d))
    .attr("y", -12)
    .attr("width", d => {
        const nextMonth = d3.timeMonth.offset(d, 1);
        return xScale(nextMonth) - xScale(d);
    })
    .attr("height", 30)
    .attr("fill", "#ccc")
    .attr("rx", 0); // Optional: rounded corners

    // Add centered text labels
    monthGroups.append("text")
    .attr("class", "month-label")
    .attr("x", d => {
        const nextMonth = d3.timeMonth.offset(d, 1);
        return xScale(d) + (xScale(nextMonth) - xScale(d)) / 2;
    })
    .attr("y", 9)
    .attr("text-anchor", "middle")
    .text(d => d3.timeFormat("%B %Y")(d));

    // Adjust the text positioning for x-axis labels to go up instead of down
    xAxis.selectAll("text")
    .style("text-anchor", "middle")
    .attr("dy", "-2.5em") // Changed to negative to move text up
    .each(function() {
        const text = d3.select(this);
        const dateText = text.text().split("\n")[0];
        const dayText = text.text().split("\n")[1];
        text.text(null);

        // Adjust tspan positioning to stack upwards
        text.append("tspan")
        .attr("x", centerOffset)
        .attr("dy", "-1.2em") // Changed to negative
        .text(dayText); // Swapped order of day and date
        
        text.append("tspan")
        .attr("x", centerOffset)
        .attr("dy", "1.2em")
        .text(dateText);
    });

    // Draw bars within zoom group
    const bars = zoomGroup.selectAll(".bar")
    .data(formattedTasks)
    .enter()
    .append("rect")
    .attr("class", "bar")
    .attr("y", d => yScale(`${d.name}`))  // Must match the format used in domain
    .attr("x", d => xScale(d.start))
    .attr("width", d => xScale(d.end) - xScale(d.start))
    .attr("height", barHeight)  // Use fixed barHeight
    .attr("fill", d => colorScale(d.name));
  }, [tasks, zoomLevel]); // Re-render chart when zoomLevel changes


  return (
    <div style={{ display: "flex" }}>
      {/* Stationary Y-Axis */}
      <svg ref={yAxisRef} style={{ flexShrink: 0 }} />
      {/* Scrollable Chart Area */}
      <div style={{ overflowX: "auto", width: "100%" }}>
        <svg ref={svgRef} />
      </div>
    </div>
  );
};

export default GanttChart;